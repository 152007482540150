<!--
 * @Date: 2023-03-13 16:11:34
 * @LastEditTime: 2023-11-29 13:23:13
 * @Description: 
 * @FilePath: \szlsnk-user-mp\src\views\user\checkout\list.vue
-->
<template>
  <div>
    <patientSelect @onConfirm="patientConfirm" />
    <load-more :data-source="dataList" :total="total" :loading="loading" @load-more="onLoad">
      <CheckoutItem :data="dataList" />
    </load-more>
  </div>
</template>
<script>
//  import { userModel } from '@/api/user'
import loadMore from '@/assets/js/mixin/load-more'
import CheckoutItem from '@/components/checkoutItem/checkoutItem.vue'
import {patientModel} from "../../../api/patient"

export default {
  name:'List',
  components:{
    CheckoutItem
  },
  mixins: [loadMore],
  data(){
    return{
      // dataList:[1,2]
      dataList:[],
      page:1,
      pageSize:10,
      patxm:'',
      zjh:'',
      total:0,
      loading: false
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    getList(){
      let params = {
        isPatAll:this.zjh == '' ? '1': '0',
        page:this.page,
        pageSize:this.pageSize,
        patxm:this.patxm,
        zjh:this.zjh
      }
      patientModel.jyReportList(params).then(res=>{
        this.dataList.push(...(res.data || []))
        this.total = res.count
      }).finally(() => {
        this.loading = false
      })
    },
    patientConfirm(value){
      this.patxm=value.text
      this.zjh=value.zjh
      this.page=1
      this.dataList=[]
      this.loading = true
      this.getList()
    },
    onLoad(){
      this.loading=true
      this.page+=1
      this.getList()
    }
  }
}
</script>
<style scoped>

</style>
