<template>
  <div class="item">
    <div v-for="(item,index) in data" :key="index" class="card-item" @click="routeTo(item)">
      <div class="title">
        报告信息
      </div>
      <div class="row">
        <div class="left">
          报告单号：
        </div>
        <div class="right">
          {{ item.bgdh }}
        </div>
      </div>
      <div class="row">
        <div class="left">
          报告类型：
        </div>
        <div class="right">
          {{ item.bglbmc }}
        </div>
      </div>
      <div class="row">
        <div class="left">
          就诊人：
        </div>
        <div class="right">
          {{ item.xm }}
        </div>
      </div>
      <div class="row">
        <div class="left">
          检验日期：
        </div>
        <div class="right">
          {{ item.jysj }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  name: 'CheckoutItem',
  props: {
    data:{
      type:Array,
      default: () => ([])
    }
  },
  methods: {
    routeTo(item) {
      this.$router.push('/checkoutdetail?patxm='+item.xm+'&zjh='+item.zjh+'&bgdh='+item.bgdh)
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";

.item {

  .card-item {
      width: 100%;
      background: #FFFFFF;
      border-radius: 12px;
      margin-bottom: 24px;
      padding: 24px 32px;
      box-sizing: border-box;

  .title {
      font-size: 32px;
      font-weight: bold;
      color: #3D3D3D;
      line-height: 45px;
      margin-bottom: 32px;
  }

  .row {
      display: flex;
      line-height: 40px;
      justify-content: space-between;
      font-size: 28px;
      margin-bottom: 24px;

      .left {
        text-align: left;
        color: #666666;
      }

      .right {
        text-align: right;
        color: #333333;
      }
    }
  }
}

</style>
