import { render, staticRenderFns } from "./checkoutItem.vue?vue&type=template&id=680fc059&scoped=true"
import script from "./checkoutItem.vue?vue&type=script&lang=js"
export * from "./checkoutItem.vue?vue&type=script&lang=js"
import style0 from "./checkoutItem.vue?vue&type=style&index=0&id=680fc059&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "680fc059",
  null
  
)

export default component.exports